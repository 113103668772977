<!-------------------------------------------------
description:  分配扣回
/**
 *@author xiaosanye 
 *@date 2023/2/14
*/
--------------------------------------------------->
<template>
  <ykc-drawer
    title="分配扣回"
    :onClose="onCLose"
    :before-close="onCLose"
    :show.sync="showDrawer"
    :before-ensure="submitForm"
    :before-cancel="beforeCancel1">
    <div class="drawer-main-body">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleFormDR">
        <ykc-form-item label="客户名称" prop="orgName">
          <span>{{ row.orgName || '—' }}</span>
        </ykc-form-item>
        <ykc-form-item label="可分配金额" prop="orgBalance">
          <ykc-input disabled readonly v-model="row.orgBalance">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="操作对象" prop="operateObject">
          <ykc-radio
            :data="[
              { id: '1', name: '下级客户' },
              { id: '2', name: '充电用户' },
            ]"
            @change="onOpObjChange"
            v-model="ruleForm.operateObject"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="操作模式" prop="operateMode">
          <ykc-radio
            :data="[
              { id: '1', name: '等额' },
              { id: '2', name: '补齐' },
              { id: '3', name: '自定义' },
            ]"
            @change="onOperateModeChange"
            v-model="ruleForm.operateMode"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item
          v-if="['1', '2'].includes(ruleForm.operateMode)"
          label="设置金额"
          prop="operateAmount">
          <ykc-input v-model="ruleForm.operateAmount">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item
          label="选择对象"
          prop="operateIdList"
          v-if="['1', '2'].includes(ruleForm.operateMode) && ruleForm.operateObject">
          <!-------------------------------------------------
          description:操作对象为逐级客户时
          --------------------------------------------------->
          <ykc-tree
            v-if="showDrawer && ['1'].includes(ruleForm.operateObject)"
            ref="objectTreeOrg"
            width="440"
            :data="treeDataOrg"
            :props="operatorProps"
            @check-change="handleTreeDataOrg"></ykc-tree>
          <!-------------------------------------------------
          description:操作对象为逐级用户时
          --------------------------------------------------->
          <ykc-tree
            v-if="showDrawer && ['2'].includes(ruleForm.operateObject)"
            ref="objectTreeUser"
            width="440"
            :data="treeDataUser"
            :props="operatorProps"
            @check-change="handleTreeDataUser"></ykc-tree>
        </ykc-form-item>
        <ykc-form-item
          v-if="showDrawer && ['3'].includes(ruleForm.operateMode)"
          class="file-error"
          label="批量操作"
          prop="file">
          <ykc-upload
            type="xlsx"
            :size="5"
            ref="file"
            :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
            @handleRemove="handleRemove"
            @handleChange="fileUpload"
            :temText="linkText"
            :temhref="modelUrl"></ykc-upload>
        </ykc-form-item>
      </ykc-form>
    </div>
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
  </ykc-drawer>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { customerAccount } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';

  export default {
    name: 'DistributionRebate',
    components: {},
    props: {
      show2: {
        type: Boolean,
        default: false,
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showDrawer: false,
        treeDataOrg: [],
        treeDataUser: [],
        operatorProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        ruleForm: {
          /**
           * 操作金额，操作模式为1，2时必填
           */
          operateAmount: '',
          /**
           * 选择对象id列表，操作模式为1，2时必填
           */
          operateIdList: '',
          /**
           * 操作对象列表，操作模式为3时必填
           */
          operateList: '',
          /**
           * 操作模式：1等额 2 补齐 3自定义
           */
          operateMode: '1',
          /**
           * 操作对象：1客户 2用户
           */
          operateObject: '1',
          /**
           * 分配机构id
           */
          orgId: '',
        },
        userAndOrgInfo: {}, // 用户和机构链接
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: done => {
                  done(); // 隐藏弹窗
                },
                onConfirm: done => {
                  done(); // 隐藏弹窗
                  this.$emit('distribution-close', false);
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.submitForm();
            },
          },
        ],
      };
    },
    computed: {
      linkText() {
        return this.ruleForm.operateObject === '1'
          ? '客户批量分配扣回.xlsx'
          : '用户批量分配扣回.xlsx';
      },
      modelUrl() {
        return this.ruleForm.operateObject === '1'
          ? `${process.env.VUE_APP_OSS}/finance/upload/%E5%AE%A2%E6%88%B7%E6%89%B9%E9%87%8F%E5%88%86%E9%85%8D%E6%89%A3%E5%9B%9E.xlsx`
          : `${process.env.VUE_APP_OSS}/finance/upload/%E7%94%A8%E6%88%B7%E6%89%B9%E9%87%8F%E5%88%86%E9%85%8D%E6%89%A3%E5%9B%9E.xlsx`;
      },
      rules() {
        const validateEqualModelAmount = (rule, value, callback) => {
          let range = {
            min: -99999999.99,
            max: 99999999.99,
          };
          range = new Proxy(range, {
            has(target, p) {
              const val = parseFloat(p);
              return val >= target.min && val <= target.max && val !== 0;
            },
          });

          const operateAmount = parseFloat(this.ruleForm.operateAmount);
          const size = this.ruleForm.operateIdList.length;
          const balance = parseFloat(this.row.orgBalance);

          if (operateAmount > 0 && operateAmount * size > balance) {
            callback(new Error('设置金额不能超过可分配金额!'));
          } else if (value in range) {
            callback();
          } else {
            callback(`请输入正确的金额（范围：-999999999.99~999999999.99,不能为0）`);
          }
        };

        // 操作模式：1等额 2 补齐 3自定义
        // 新增逻辑
        // 1. 操作模式：等额
        // 设置金额框：-99999999 ~ 99999999，精确到小数点后两位，正数表示给下级分配，负数表示从下级扣回，不能为0。
        if (['1'].includes(this.ruleForm.operateMode)) {
          return {
            orgId: [{ required: true, message: '请输入分配机构id', trigger: 'blur' }],
            operateObject: [{ required: true, message: '请选择操作对象', trigger: 'change' }],
            operateMode: [{ required: true, message: '请选择操作模式', trigger: 'change' }],
            operateAmount: [
              {
                required: true,
                message: regexpObj.regexp.input.amountNumberType.errorTips.empty(),
                trigger: 'blur',
              },
              {
                validator: validateEqualModelAmount,
                trigger: 'blur',
              },
            ],
            operateIdList: [
              { type: 'array', required: true, message: '请选择对象', trigger: 'change' },
            ],
          };
        }
        // 2 补齐 设置金额框：0 ~ 99999999，精确到小数点后两位，可以为0
        if (['2'].includes(this.ruleForm.operateMode)) {
          return {
            orgId: [{ required: true, message: '请输入分配机构id', trigger: 'blur' }],
            operateObject: [{ required: true, message: '请选择操作对象', trigger: 'change' }],
            operateMode: [{ required: true, message: '请选择操作模式', trigger: 'change' }],
            operateAmount: [
              {
                required: true,
                message: regexpObj.regexp.input.amountNumberType.errorTips.empty(),
                trigger: 'blur',
              },
              {
                pattern: regexpObj.regexp.input.amountNumberType.regexp,
                message: regexpObj.regexp.input.amountNumberType.errorTips.error(),
                trigger: 'blur',
              },
            ],
            operateIdList: [
              { type: 'array', required: true, message: '请选择对象', trigger: 'change' },
            ],
          };
        }
        // 3 自定义模式
        return {
          orgId: [{ required: true, message: '请输入分配机构id', trigger: 'blur' }],
          operateObject: [{ required: true, message: '请选择操作对象', trigger: 'change' }],
          operateMode: [{ required: true, message: '请选择操作模式', trigger: 'change' }],
          operateList: [
            { type: 'array', required: true, message: '请选择操作对象列表', trigger: 'change' },
          ],
          file: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.file.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        };
      },
    },
    watch: {
      show2(newValue, oldValue) {
        console.log('oldValue:', oldValue);
        this.showDrawer = newValue;
      },
      row(newValue, oldValue) {
        console.log('oldValue:row', oldValue);
        this.ruleForm.orgId = newValue?.orgId;
        this.ruleForm.orgName = newValue?.orgName;
        this.doResetSomeField();

        if (oldValue.orgId !== newValue.orgId) {
          this.queryUserAndOrgInfo4Finance();
        }
      },
    },
    methods: {
      onOpObjChange(eventTarget) {
        console.log('evt:', eventTarget);
        this.$nextTick(() => {
          this.$refs.ruleFormDR.clearValidate();
        });
      },
      doResetSomeField() {
        this.ruleForm = {
          ...this.ruleForm,
          /**
           * 操作金额，操作模式为1，2时必填
           */
          operateAmount: '',
          /**
           * 选择对象id列表，操作模式为1，2时必填
           */
          operateIdList: '',
          /**
           * 操作对象列表，操作模式为3时必填
           */
          operateList: '',
          /**
           * 操作模式：1等额 2 补齐 3自定义
           */
          operateMode: '1',
          /**
           * 操作对象：1客户 2用户
           */
          operateObject: '1',
        };
        this.$nextTick(() => {
          this.$refs?.ruleFormDR?.clearValidate();
        });
      },
      // 监听操作模式改变 1 2 3
      onOperateModeChange(val) {
        console.log('onOperateModeChange val:', val);
        if (['1', '2'].includes(val)) {
          this.ruleForm.operateIdList = [];
          this.treeDataOrg = [...this.treeDataOrg];
          this.treeDataUser = [...this.treeDataUser];
        }
      },
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        this.$emit('distribution-close', false);
        done();
      },
      onCLose() {
        // this.showDrawer = false;
        this.$emit('distribution-close', false);
      },
      handleTreeDataOrg() {
        this.ruleForm.operateIdList = this.$refs.objectTreeOrg.getCheckedKeys(false) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleTreeDataUser() {
        this.ruleForm.operateIdList = this.$refs.objectTreeUser.getCheckedKeys(false) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      // 查询用户和组织信息  财务
      queryUserAndOrgInfo4Finance() {
        customerAccount.queryUserAndOrgInfo4Finance({ id: this.row.orgId }).then(res => {
          this.userAndOrgInfo = res;
          this.treeDataUser = this.userAndOrgInfo.users.map(item => ({
            id: item.id,
            name: item.userAccount,
          }));
          this.treeDataOrg = this.userAndOrgInfo.orgs.map(item => ({
            id: item.id,
            name: item.orgName,
          }));
        });
      },
      fileUpload(file, fileList, isCheck) {
        // check the value of isCheck
        if (isCheck) {
          // assign the raw value of file to the file object on ruleForm
          this.ruleForm.file = file.raw;
          // make a request to the importDistributionDeduction api using the raw value of file as a parameter
          // importMsg {"orgId":"1","operateObject","1","operateMode":"3"}
          const formData = new FormData();
          // Create a variable to hold our JSON string
          const msg = JSON.stringify({
            orgId: this.ruleForm.orgId,
            operateObject: this.ruleForm.operateObject,
            operateMode: this.ruleForm.operateMode,
          });
          // Log the value of msg to the console
          console.log('upload msg:', msg);
          // Create an object to contain all the values we wish to include.
          const param = {
            file: this.ruleForm.file,
            paramJson: msg,
          };
          // Loop through each item in the param object, and append it to the FormData object.
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });

          customerAccount
            .importDistributionDeduction(formData)
            .then(res => {
              // log the res
              console.log('res---导入分配扣回信息:\n', res);
              this.$message({
                message: '导入文件成功',
                type: 'success',
              });
              this.ruleForm.operateList = res || [];
            })
            .catch(() => {
              this.$refs.file.fileList = [];
            });
        }
        // validate the ruleForm's file object
        this.$refs?.ruleFormDR?.validateField('file');
      },
      submitForm() {
        this.$refs?.ruleFormDR?.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              const formData = {
                /**
                 * 操作金额，操作模式为1，2时必填
                 */
                operateAmount: this.ruleForm.operateAmount,
                /**
                 * 选择对象id列表，操作模式为1，2时必填
                 */
                operateIdList: this.ruleForm.operateIdList,
                /**
                 * 操作对象列表，操作模式为3时必填
                 */
                // operateList: this.ruleForm.operateList,
                /**
                 * 操作模式：1等额 2 补齐 3自定义
                 */
                operateMode: this.ruleForm.operateMode,
                /**
                 * 操作对象：1客户 2用户
                 */
                operateObject: this.ruleForm.operateObject,
                /**
                 * 分配机构id
                 */
                orgId: this.ruleForm.orgId,
              };
              delete formData.operateList;
              if (formData.operateMode === '3') {
                formData.operateList = this.ruleForm.operateList;
                delete formData.operateIdList;
              }
              // todo  校验规则正确后 请求接口
              customerAccount
                .allocateOrTakeBack(formData)
                // eslint-disable-next-line no-unused-vars
                .then(res => {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                  this.$emit('distribution-close', false);
                  this.$emit('refresh-list', true);
                })
                .catch(err => {
                  console.error(err);
                  if (err) {
                    this.$message.warning(err?.resultDesc);
                  }
                });
            } catch (e) {
              console.error(e);
            }
          } else {
            this.formValid = false;
          }
        });
      },
    },
  };
</script>

<style scoped></style>
